import React, { MouseEventHandler } from "react";
import "./board.css";

const Board = ({ gridState, solvedGrid, handleClick, selectedTile, history, checkCell }) => {
  const board = gridState.map((row, i) => {
    let horizontalBorder = i === 2 || i === 5 ? "bottom-border" : i === 3 || i === 6 ? "top-border" : "";
    return row.map((val, j) => {
      // Styling
      let verticalBorder = j === 2 || j === 5 ? "right-border" : j === 3 || j === 6 ? "left-border" : "";
      let selectedTileStyling = i === selectedTile.row && j === selectedTile.col ? "selected-tile" : "";
      let isUserInput = val === 0 || history.some((e) => e.row === i && e.col === j);
      // If a user inputs an incorrect value, the tile will have a red font color. Otherwise the color is blue
      let userInputStyling = isUserInput ? (val !== 0 ? (checkCell ? (solvedGrid[i][j] === val ? "text-blue-700" : "text-red-600") : "text-blue-700") : "") : "default-tiles";
      // let highlightedTile = selectedTile.row !== -1 && selectedTile.col !== -1 && gridState[selectedTile.row][selectedTile.col] !== 0 && selectedTile.row !== i && selectedTile.col !== j && val === gridState[selectedTile.row][selectedTile.col] ? "highlighted" : "";
      let highlightedTile = "";
      return (
        <div onClick={() => handleClick(i, j, val, isUserInput)} className={`tile ${verticalBorder} ${horizontalBorder} ${selectedTileStyling} ${userInputStyling} ${highlightedTile}`} id={`${i}-${j}`} key={`${i}-${j}`}>
          {val === 0 ? "" : val}
        </div>
      );
    });
  });

  return <div className="grid w-[360px] h-[360px] sm:w-[450px] sm:h-[450px]">{board}</div>;
};

export default Board;
