import React, { useContext, useEffect, useState } from "react";
// import { Tile as TileModel } from "../models/tile";
import Board from "./Board";
import Score from "./Score";
import styles from "../../styles/index.module.css";
import { ReplayIcon } from "../../components/ReplayIcon";
import { GameContext } from "../../context/game-context";
import Splash from "../../components/Splash";

export default function Game2048() {
  const { startGame, score } = useContext(GameContext);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (score === 0) setStatus(false);
  }, [score]);

  const handleReset = () => {
    if (score !== 0) setStatus(true);
  };

  return (
    <div className={`${styles.twenty48} h-full flex flex-col`}>
      <div className="flex items-center flex-col">
        {status === true && score !== 0 && <Splash heading="Start a new game?" onOk={startGame} onCancel={() => setStatus(false)} />}
        <button aria-label="replay" className="ml-auto" onClick={handleReset}>
          <ReplayIcon />
        </button>
        <div className="flex items-center w-full">
          <h1 style={{ flex: "1 1" }}>2048</h1>
          <Score />
        </div>
      </div>
      <Board />
    </div>
  );
}
