import Game2048 from "./pages/2048/Index";
import Game8puzzle from "./pages/8-puzzle/Index";
import Maze from "./pages/maze/Index";
import Sudoku from "./pages/sudoku/Index";

const AppRoutes = [
  {
    index: true,
    element: <Game2048 />,
  },
  {
    path: "/2048",
    element: <Game2048 />,
  },
  {
    path: "/8-puzzle",
    element: <Game8puzzle />,
  },
  {
    path: "/maze",
    element: <Maze />,
  },
  {
    path: "/sudoku",
    element: <Sudoku />,
  },
];

export default AppRoutes;
