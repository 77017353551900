import styles from "./splash.module.css";
import React from "react";

export default function Splash({ heading = "You won!", onOk, onCancel }) {
  return (
    <div className={`${styles.splash} p-4 bg-[#33312bb3]`}>
      <div>
        <h1 className="text-2xl">{heading}</h1>
        <div className="flex gap-2 justify-around">
          <button className={`${styles.button} w-28 rounded-lg cursor-pointer bg-[#8f7a66] text-white`} onClick={onOk}>
            Ok
          </button>
          <button className={`${styles.button} w-28 rounded-lg cursor-pointer bg-white text-[#8f7a66]`} onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
