import React, { useState } from "react";
import Board from "./components/Board/Board";
import Keypad from "./components/Keypad/Keypad";
import { SudokuGrids } from "./Constant";
import { generateGrid } from "./Util";
import Splash from "../../components/Splash";
import { ReplayIcon } from "../../components/ReplayIcon";

const Sudoku = () => {
  const randomElement = SudokuGrids[Math.floor(Math.random() * SudokuGrids.length)];
  const [grid, setGrid] = useState(generateGrid(randomElement.grid));
  const [solvedGrid, setSolvedGrid] = useState(generateGrid(randomElement.solvedGrid));
  const [selectedTile, setSelectedTile] = useState({
    row: -1,
    col: -1,
    isMutable: false,
  });
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState(false);
  const [checkCell, setCheckCell] = useState(false);

  const handleRestart = () => {
    let random = SudokuGrids[Math.floor(Math.random() * SudokuGrids.length)];
    setGrid(generateGrid(random.grid));
    setSolvedGrid(generateGrid(random.solvedGrid));
    setSelectedTile({
      row: -1,
      col: -1,
      isMutable: false,
    });
    setHistory([]);
    setStatus(false);
  };

  const handleTileClick = (row, col, val, isUserInput) => {
    setSelectedTile((prev) => {
      const sameAsPrev = prev.row === row && prev.col === col;
      return sameAsPrev ? { row: -1, col: -1, isMutable: false } : { row: row, col: col, isMutable: isUserInput };
    });
  };

  const handleKeypadClick = async (val) => {
    const hasSelectedTile = selectedTile.row !== -1 && selectedTile.col !== -1;
    if (hasSelectedTile && selectedTile.isMutable) {
      setHistory((prevHistory) => [
        ...prevHistory,
        {
          prev: grid[selectedTile.row][selectedTile.col],
          row: selectedTile.row,
          col: selectedTile.col,
        },
      ]);
      setGrid((prevGrid) => {
        const newGrid = [...prevGrid];
        newGrid[selectedTile.row][selectedTile.col] = val;
        return newGrid;
      });
    }
  };

  const handleFnBtnClick = (fn) => {
    switch (fn) {
      // Undo btn
      case "undo": {
        const updatedHistory = [...history];
        const lastHistory = updatedHistory.pop();

        if (!lastHistory) break;

        let updatedGrid = grid.map((arr) => arr.slice());
        updatedGrid[lastHistory.row][lastHistory.col] = lastHistory.prev;

        setGrid(updatedGrid);
        setHistory(updatedHistory);
        break;
      }
      // Erase btn
      case "erase": {
        if (selectedTile.row == -1 || selectedTile.col == -1 || !selectedTile.isMutable) break;
        if (grid[selectedTile.row][selectedTile.col] == 0) break;

        eraseTile();

        async function eraseTile() {
          setHistory((prevHistory) => [
            ...prevHistory,
            {
              prev: grid[selectedTile.row][selectedTile.col],
              row: selectedTile.row,
              col: selectedTile.col,
            },
          ]);
          setGrid((prev) => {
            const newGrid = [...prev];
            newGrid[selectedTile.row][selectedTile.col] = 0;
            return newGrid;
          });
        }

        break;
      }
      // Pencil btn
      case "pencil": {
        alert("Pencil tool is not implemented");
        break;
      }
      default: {
        console.log("Function specified does not exist");
      }
    }
  };

  const handleReset = () => {
    setStatus(true);
  };

  return (
    <div className="m-auto flex items-center content-center flex-col pt-2">
      {status === true && <Splash heading="Start a new game?" onOk={handleRestart} onCancel={() => setStatus(false)} />}
      <div className="w-[360px] sm:w-[450px] flex">
        <div className="inline-block">
          <input type="checkbox" id="checkcell" name="checkcell" className="align-middle mr-1 cursor-pointer" onChange={() => setCheckCell(!checkCell)} />
          <label htmlFor="checkcell" className="align-middle cursor-pointer">
            Check cell
          </label>
        </div>
        <button aria-label="replay" className="ml-auto pb-2" onClick={handleReset}>
          <ReplayIcon />
        </button>
      </div>
      <Board checkCell={checkCell} gridState={grid} solvedGrid={solvedGrid} handleClick={handleTileClick} selectedTile={selectedTile} history={history} />
      {JSON.stringify(grid) === JSON.stringify(solvedGrid) && (
        <div>
          <h1 className="text-2xl">{`You win!`}</h1>
        </div>
      )}
      {JSON.stringify(grid) !== JSON.stringify(solvedGrid) && <Keypad handleClick={handleKeypadClick} />}
    </div>
  );
};

export default Sudoku;
